<template>
  <div>
    <ProfileCard>
      <template v-slot:messageWhenParticipantTabIsNotEditable>
        <v-alert border="top" type="warning">
          Some information is not editable. To maintain the integrity of the Sony contract database, all edits are to be
          requested via email to:
          <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a>
        </v-alert>
      </template>
      <template v-slot:messageWhenOrganizationIsNotEditable>
        <v-alert border="top" type="warning">
          Information is not editable. To maintain the integrity of the Sony contract database, all edits are to be
          requested via email to:
          <a :href="'mailto:' + selectedProgram.programEmail">{{ selectedProgram.programEmail }}</a>
        </v-alert>
      </template>
    </ProfileCard>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ProfileCard from "../../../gapp-components/components/common/profile/ProfileCard.vue";

export default {
  components: { ProfileCard },
  name: "Profile",
  metaInfo: {
    title: "Profile"
  },

  methods: {
    breadcrumb() {
      this.$store.dispatch("setBreadcrumb", [
        {
          text: this.$i18n.translate("Dashboard"),
          to: { name: "dashboard" },
          exact: true
        },
        { text: this.$i18n.translate("Profile") }
      ]);
    }
  },
  mounted() {
    this.breadcrumb();
  },
  computed: {
    ...mapGetters(["selectedProgram", "selectedParticipant"])
  }
};
</script>
