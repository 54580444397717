<template>
  <v-card flat v-bind="$attrs" v-on="$listeners">
    <v-container>
      <v-alert border="top" type="info" class="mt-0">
        The following roles and privileges are assigned to you. Expand each role to view your privileges.
      </v-alert>

      <v-expansion-panels accordion focusable>
        <v-expansion-panel v-for="role in selectedParticipant.user.roles" :key="role.name">
          <v-expansion-panel-header>{{ role.description ? role.description : role.name }}</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-container>
              <v-list dense>
                <v-list-item v-for="privilege in role.privileges" :key="privilege.id">
                  <template v-slot:default>
                    <v-list-item-content>
                      <v-list-item-title>{{ privilege.description }}</v-list-item-title>
                      <v-list-item-subtitle>{{ privilege.longDescription }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProfilePrivilegesTab",
  data: () => ({
    selectedPrivileges: []
  }),
  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
