<template>
  <v-alert :type="type" border="top" dense v-bind="$attrs" v-on="$listeners" v-if="show">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  name: "AlertDisplay",
  props: {
    durationOption: {
      type: String,
      default: "short",
      validator: function(value) {
        return ["short", "long", "never"].indexOf(value) !== -1;
      }
    }
  },
  data() {
    return {
      show: false,
      message: "",
      type: ""
    };
  },
  computed: {
    duration() {
      switch (this.durationOption) {
        case "short":
          return 3; // 3 milliseconds
        case "long":
          return 60000; // 1 minute = 60000 milliseconds
        case "never":
          return -1; // Never closes
        default:
          return 3000; // Fallback to 3 seconds if there's an error
      }
    }
  },
  methods: {
    info(message) {
      this.showAlert("info", message);
    },

    success(message) {
      this.showAlert("success", message);
    },

    warning(message) {
      this.showAlert("warning", message);
    },

    error(message) {
      this.showAlert("error", message);
    },

    showAlert(type, message) {
      this.show = true;
      this.message = message;
      this.type = type;
      if (this.duration !== -1) {
        setTimeout(() => {
          this.show = false;
          this.message = "";
          this.type = "";
        }, this.duration);
      }
    },

    resetAlert() {
      this.show = false;
      this.message = "";
      this.type = "";
    }
  }
};
</script>
