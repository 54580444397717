<template>
  <v-card flat v-bind="$attrs" v-on="$listeners" :loading="loading">
    <div class="text-center" v-if="loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-form @submit.prevent="onPasswordSubmit" v-if="!loading" v-model="validPasswordForm">
      <v-container>
        <v-alert border="top" type="info">
          To change your password, enter a new password using the following password rules.
        </v-alert>
        <v-alert type="success" v-model="passwordAlert" close-icon="mdi-close" dismissible
          >Password was successfully changed
        </v-alert>
        <v-alert type="error" v-model="passwordFailedAlert" close-icon="mdi-close" dismissible
          >Password failed to change
        </v-alert>
        <v-row>
          <v-col cols="6" class="pt-0">
            <PasswordField
              v-if="programGroup"
              name="newPassword"
              label="New Password"
              id="newPassword"
              :rules="rules.password"
              v-model="passwordForm.newPassword"
              :programGroup="programGroup"
              :error-messages="$error.getValidationError(errors, 'password')"
              @input="$error.clearValidationError(errors, 'password')"
            ></PasswordField>

            <PasswordField
              v-if="programGroup"
              name="confirmPassword"
              label="Confirm Password"
              id="confirmPassword"
              :rules="rules.confirmPassword"
              v-model="passwordForm.confirmPassword"
              :error-messages="$error.getValidationError(errors, 'confirmPassword')"
              :programGroup="programGroup"
              @input="$error.clearValidationError(errors, 'confirmPassword')"
            ></PasswordField>
          </v-col>
          <v-col cols="6">
            <p><b>Password Rules:</b></p>
            <ul>
              <li v-if="programGroup && programGroup.passwordMinimumCharacters">
                Minimum length of {{ programGroup.passwordMinimumCharacters }} characters
              </li>
              <li v-if="programGroup && programGroup.passwordMinimumDigits">
                Include at least {{ programGroup.passwordMinimumDigits }}
                {{ programGroup.passwordMinimumDigits == 1 ? "number" : "numbers" }}
              </li>
              <li v-if="programGroup && programGroup.passwordMinimumAlpha">
                Include at least {{ programGroup.passwordMinimumAlpha }}
                {{ programGroup.passwordMinimumDigits == 1 ? "letter" : "letters" }}
              </li>
              <li v-if="programGroup && programGroup.passwordMinimumSpecial">
                Include at least {{ programGroup.passwordMinimumSpecial }}
                {{ programGroup.passwordMinimumSpecial == 1 ? "special character" : "special characters" }}
                ({{ programGroup.passwordAllowableSpecialCharacters }})
              </li>
              <li v-if="programGroup && programGroup.passwordPreviousPasswordCheck">
                Not the same as a previous password
              </li>
              <li v-if="programGroup && programGroup.passwordUsernameCheck">
                Not the same as your username
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn type="submit" color="primary" @click="onSubmit" :disabled="!validPasswordForm">Update</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/api.service";
import PasswordField from "../../fields/PasswordField";

export default {
  name: "ProfilePasswordTab",
  components: {
    PasswordField
  },
  data: () => ({
    loading: false,
    validPasswordForm: true,
    passwordAlert: false,
    passwordFailedAlert: false,
    passwordForm: {},
    errors: {},
    rules: {},
    programGroup: null
  }),
  mounted() {
    this.programGroup = this.selectedProgram.programGroup;
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.errors = {};
      this.passwordForm.sendEmail = true;
      ApiService.post("/api/users/changePassword", this.passwordForm)
        .then(() => {
          this.passwordAlert = true;
          this.passwordForm = {};
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.passwordAlert = false;
          }, 4000);
          this.loading = false;
        })
        .catch(error => {
          this.errors = ApiService.getErrorsFromResponse(error);
          this.loading = false;
          this.passwordFailedAlert = true;
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.passwordFailedAlert = false;
          }, 5000);
        });
    },
    onEdit() {
      this.passwordForm = {};
    }
  },
  computed: {
    ...mapGetters(["selectedProgram"])
  }
};
</script>
