<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      class="elevation-1"
      hide-default-footer
      :loading="loading"
      :no-data-text="
        noPaymentContactParticipant ? 'No payment contact participant. Create one.' : 'No Bank Accounts on File'
      "
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title v-if="showTitle">Bank Accounts</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip
            bottom
            v-if="
              (participant &&
                selectedParticipant &&
                (selectedParticipant.id == participant.id || $privilege.hasPrivilege('PLAID_ACCOUNT_CREATE'))) ||
                (organization && $privilege.hasPrivilege('PLAID_ACCOUNT_CREATE'))
            "
          >
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn v-on="{ ...onTooltip }" small class="ml-2" @click.stop="addPlaidAccount">
                <v-icon>mdi-plus</v-icon> Bank Account
              </v-btn>
            </template>
            <span>Add New Bank Account</span>
          </v-tooltip>
          <v-tooltip bottom v-if="organization">
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn
                v-on="{ ...onTooltip }"
                small
                class="ml-2"
                @click.stop="$router.push({ name: 'participant', params: { id: 0 } })"
              >
                <v-icon>mdi-person</v-icon> Create Payment Contact Participant
              </v-btn>
            </template>
            <span>Create Payment Contact Participant</span>
          </v-tooltip>
        </v-toolbar>
      </template>
      <template v-slot:item.primary="{ item }">
        <v-icon v-if="item.primary" color="green">mdi-check-circle-outline</v-icon>
      </template>
      <template v-slot:item.reauthenticationNeeded="{ item }">
        <span v-if="item.reauthenticationNeeded">Yes</span>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn v-if="item.plaidAccount" class="ma-1" :disabled="loading" text small @click="updatePlaidAccount(item)"
          >Reauthenticate</v-btn
        >
        <v-btn
          v-if="!item.primary"
          color="success"
          class="ma-1"
          :disabled="loading"
          text
          small
          @click="onMakePrimaryBankAccount(item)"
          >Make Primary</v-btn
        >
        <v-btn color="red" class="ma-1" :disabled="loading" text small @click="onRemoveBankAccount(item)">{{
          $i18n.translate("Remove")
        }}</v-btn>
      </template>
      <template v-slot:item.provider="{ item }">
        <span v-if="item.plaidAccount">Plaid</span>
        <span v-else>N/A</span>
      </template>
    </v-data-table>
    <PlaidDialog
      v-model="plaidDialog"
      :participant="finalParticipant"
      :language="selectedLocale.languageType"
      :existingPlaidAccount="plaidAccountToUpdate"
      @complete="completePlaidAccountLink"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/api.service";
import PlaidDialog from "../common/transaction/PlaidDialog";

export default {
  name: "BankAccountsTable",
  props: {
    participant: Object,
    showTitle: { type: Boolean, default: true },
    showProvider: { type: Boolean, default: false },
    organization: Object
  },
  components: {
    PlaidDialog
  },
  data: () => ({
    loading: false,
    items: [],
    plaidDialog: null,
    plaidAccountToUpdate: null,
    user: null,
    headers: [],
    noPaymentContactParticipant: false
  }),
  methods: {
    fetchUser() {
      this.loading = true;
      if (this.participant) {
        return ApiService.get("/api/participants/" + this.participant.id + "/user").then(({ data }) => {
          this.user = data;
          this.loading = false;
        });
      } else if (this.organization && this.organization.paymentContactParticipant) {
        return ApiService.get("/api/participants/" + this.organization.paymentContactParticipant.id + "/user")
          .then(({ data }) => {
            this.user = data;
            this.loading = false;
          })
          .catch(() => {
            this.noPaymentContactParticipant = true;
            this.loading = false;
          });
      }
    },
    fetchBankAccounts() {
      this.loading = true;
      if (!this.user || this.noPaymentContactParticipant) {
        this.loading = false;
        this.items = [];
        return;
      }

      return ApiService.post("/api/bankAccounts/search", { isEffective: "Active", user: { id: this.user.id } })
        .then(({ data }) => {
          this.sortAccounts(data.content);
          this.items = data.content;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onMakePrimaryBankAccount(item) {
      this.loading = true;
      return ApiService.get("/api/bankAccounts/" + item.id + "/makePrimary")
        .then(() => {
          return this.fetchBankAccounts();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onRemoveBankAccount(item) {
      this.loading = true;
      return ApiService.delete("/api/bankAccounts/" + item.id + "/expire")
        .then(() => {
          return this.fetchBankAccounts();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    sortAccounts(accounts) {
      accounts.sort((a, b) => {
        let ret = a.primary ? -1 : b.primary ? 1 : 0;
        if (ret == 0) {
          ret = a.displayName.toUpperCase().localeCompare(b.displayName.toUpperCase());
        }
        if (ret == 0) {
          ret = a.accountNumberMask.toUpperCase().localeCompare(b.accountNumberMask.toUpperCase());
        }
        return ret;
      });
    },
    addPlaidAccount() {
      this.plaidDialog = "CREATE";
    },
    updatePlaidAccount(bankAccount) {
      this.plaidAccountToUpdate = bankAccount.plaidAccount;
      this.plaidDialog = "UPDATE";
    },
    completePlaidAccountLink() {
      this.plaidDialog = null;
      this.fetchBankAccounts();
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedLocale"]),
    finalParticipant() {
      if (this.participant) {
        return this.selectedParticipant.id != this.participant.id ? this.participant : null;
      } else if (this.organization) {
        return this.organization.paymentContactParticipant;
      } else {
        console.log("Can't define final participant.");
        return null;
      }
    }
  },
  mounted() {
    this.headers = [
      {
        value: "bankName",
        text: "Bank Name"
      },
      {
        value: "accountNumberMask",
        text: "Account Number"
      },
      {
        value: "primary",
        text: "Primary?",
        align: "center"
      },
      {
        value: "reauthenticationNeeded",
        text: "Reauthentication Needed?",
        align: "center"
      }
    ];

    if (this.showProvider) {
      this.headers.push({
        value: "provider",
        text: "Provider"
      });
    }

    this.headers.push({
      value: "actions",
      text: "Actions",
      align: "right"
    });

    if (this.participant && this.participant.id) {
      this.fetchUser().then(() => this.fetchBankAccounts());
    }
  }
};
</script>
