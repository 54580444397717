<template>
  <v-select
    :value="value"
    @input="onInput"
    v-bind="$attrs"
    v-on="$listeners"
    :items="genderOptions"
    item-value="id"
    item-text="name"
    clearable
    return-value
  >
  </v-select>
</template>

<script>
export default {
  name: "GenderField",
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      genderOptions: [
        { id: "M", name: "Male" },
        { id: "F", name: "Female" },
        { id: "O", name: "Other" }
      ]
    };
  },
  methods: {
    onInput(selectedId) {
      this.$emit("input", selectedId);
    }
  }
};
</script>
