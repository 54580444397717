<template>
  <v-card flat v-bind="$attrs" v-on="$listeners" :loading="loading">
    <v-container>
      <v-row>
        <v-col class="pb-0">
          <v-alert border="top" type="info">
            The following types of emails can be opted in or opted out.
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table :headers="headers" :items="items" :loading="loading">
            <template v-slot:item.name="{ item }">
              <span>{{ item.name }}</span>
              <br />
              <ul style="list-style-type: none">
                <li>
                  <span style="font-style: italic">{{ item.description }} </span>
                </li>
              </ul>
            </template>

            <template v-slot:item.suppressed="{ item }">
              <v-checkbox v-model="item.suppressed" @change="item.dirty = true" :ripple="false"></v-checkbox>
            </template>
          </v-data-table>
          <br />
          <div class="plaid-link-wrapper">
            <v-btn @click="savePreferences()" :disabled="loading" color="primary">Update</v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../services/api.service";

export default {
  name: "ProfileEmailTemplateUnsubscribeGroupTab",
  props: {
    participant: Object
  },
  data: () => ({
    loading: true,
    errors: {},
    panel: [],
    items: [],
    headers: [
      {
        value: "name",
        text: "Email"
      },
      {
        value: "suppressed",
        text: "Opt Out"
      }
    ]
  }),
  methods: {
    fetchUnsubscribeGroups() {
      this.loading = true;
      return ApiService.get("/api/emailTemplateUnsubscribeGroups/byParticipant")
        .then(({ data }) => {
          this.items = data;
        })
        .catch(e => {
          console.log(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    savePreferences() {
      this.loading = true;
      let promises = [];

      this.items.map(item => {
        if (item.dirty) {
          if (item.suppressed) {
            promises.push(
              ApiService.post("/api/emailTemplateUnsubscribeGroups/" + item.id + "/subscribe", {
                email: this.selectedParticipant.email1
              })
            );
          } else {
            promises.push(
              ApiService.delete(
                "/api/emailTemplateUnsubscribeGroups/" + item.id + "/unsubscribe/" + this.selectedParticipant.email1
              )
            );
          }
        }
      });

      Promise.all(promises).then(() => {
        this.loading = false;
      });
    }
  },
  mounted() {
    this.fetchUnsubscribeGroups();
  },

  computed: {
    ...mapGetters(["selectedParticipant"])
  }
};
</script>
